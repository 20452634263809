import request from '../utility/request'
import { serialize } from '../utility/formHelpers'
import { ScrollData } from '../types/interfaces'

export const setScrollMarkers = (): void => {
  const articlePage = document.querySelector('.article__page__body')

  if (articlePage != null) {
    const articleElements = Array.prototype.slice.call(articlePage.children)
    const articleBodyContent = document.querySelector('.article__content')

    if (articleBodyContent != null) {
      const bodyArray: HTMLElement[] = Array.prototype.slice.call(
        articleBodyContent.children
      )
      articleElements.push(bodyArray)
    }

    const flattened = articleElements.reduce(
      (flat: HTMLElement[], val: HTMLElement) => flat.concat(val),
      []
    )

    const progressIncrements = [0.25, 0.5, 0.75]

    progressIncrements.forEach(function (progress: number) {
      const distance: number = Math.round(flattened.length * progress)
      const element: HTMLElement = flattened[distance]
      const elementIdValue: string = progress.toString().replace(/^[0.]+/, '')
      element.id = `Metrics-${elementIdValue}`
    })

    const fifty: HTMLElement | null = document.getElementById('Metrics-5')

    if (fifty != null) {
      fifty.id = 'Metrics-50'
    }

    // 100% marked separately due to arrays being 0 indexed
    const lastNum: number = flattened.length - 1
    const lastElement: HTMLElement = flattened[lastNum]
    lastElement.id = 'Metrics-100'
  }
}

export const scrollDepth = function (): void {
  setScrollMarkers()
  // Intersection Observer for scroll depth
  const scrollNodeList: NodeListOf<HTMLElement> = document.querySelectorAll(
    '#Metrics-100, #Metrics-75, #Metrics-50, #Metrics-25'
  )
  const scrollTargets: HTMLElement[] = Array.prototype.slice.call(
    scrollNodeList
  )

  const scrollObserver = function (scrollTarget: HTMLElement): void {
    const io = new IntersectionObserver(function (
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver
    ) {
      entries.forEach(function (entry: IntersectionObserverEntry) {
        if (entry.isIntersecting) {
          const scrollData: ScrollData = {
            locale: window.OP_METRICS.locale,
            pathname: document.location.pathname,
            referrer: window.OP_METRICS.referrer,
            screenHeight: window.OP_METRICS.screen_height,
            screenWidth: window.OP_METRICS.screen_width,
            version: window.OP_METRICS.site_version,
            template: window.OP_METRICS.template,
            elementSelector: entry.target.id,
            timeSeen: entry.time.toString()
          }

          observer.disconnect()
          request('POST', '/element_impression_counts', serialize(scrollData))
        }
      })
    })

    io.observe(scrollTarget)
  }

  scrollTargets.forEach(scrollObserver)
}
