export const paintMetrics = (): { [k: string]: number } | undefined => {
  if (
    typeof window.performance.getEntriesByType === 'function' &&
    window.performance.getEntriesByType('paint') != null &&
    window.performance.getEntriesByType('paint').length > 0
  ) {
    const paintEntries = performance.getEntriesByType('paint')

    const paintTimingsObject: {
      [k: string]: number
    } = paintEntries.reduce((acc, paintEntry) => {
      return { ...acc, [paintEntry.name]: paintEntry.startTime }
    }, {})
    return paintTimingsObject
  } else {
    return undefined
  }
}
