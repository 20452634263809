export const performanceTimeline = ():
  | PerformanceEntry
  | PerformanceTiming
  | undefined => {
  if (
    typeof window.performance.getEntriesByType === 'function' &&
    window.performance.getEntriesByType('navigation') != null &&
    window.performance.getEntriesByType('navigation').length > 0
  ) {
    const navTiming = window.performance.getEntriesByType('navigation')
    return navTiming[0]
  } else if (
    typeof window.performance.timing === 'object' &&
    window.performance.timing != null
  ) {
    return window.performance.timing
  } else {
    return undefined
  }
}
