export const pageWeight = (): PageWeightObject | undefined => {
  if (
    typeof window.performance.getEntriesByType === 'function' &&
    window.performance.getEntriesByType('resource') != null &&
    window.performance.getEntriesByType('resource').length > 0
  ) {
    const resourceTimings = window.performance.getEntriesByType(
      'resource'
    ) as PerformanceResourceTiming[]

    const pageWeight = resourceTimings
    const transferSize = pageWeight.reduce(
      (acc, pageWeight) => acc + pageWeight.transferSize,
      0
    )
    const encodedBodySize = pageWeight.reduce(
      (acc, pageWeight) => acc + pageWeight.encodedBodySize,
      0
    )
    const decodedBodySize = pageWeight.reduce(
      (acc, pageWeight) => acc + pageWeight.decodedBodySize,
      0
    )

    return {
      transferSize: transferSize,
      encodedBodySize: encodedBodySize,
      decodedBodySize: decodedBodySize
    }
  } else {
    return undefined
  }
}
