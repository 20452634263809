export function networkConnection ():
  | {
      effectiveType: string | undefined
      rtt: number | undefined
      saveData: boolean | undefined
    }
  | undefined {
  if (typeof navigator === 'object') {
    const connection =
      navigator.connection ??
      navigator.mozConnection ??
      navigator.webkitConnection ??
      navigator.msConnection

    if (connection != null) {
      return {
        effectiveType: connection.effectiveType,
        rtt: connection.rtt,
        saveData: connection.saveData
      }
    } else {
      return undefined
    }
  } else {
    return undefined
  }
}
