import getCSRFToken from './csrfToken'

export default function request (
  method: 'GET' | 'POST',
  url: string,
  content?: any,
  callback?: (response: XMLHttpRequest['response']) => void,
  errorCallback?: (err: any) => void
): void {
  const request = new XMLHttpRequest()
  request.open(method, url, true)
  request.onload = () => {
    if (request.status >= 200 && request.status < 400) {
      // Success!
      const data = JSON.parse(request.response)
      callback?.(data)
    }
  }

  request.onerror = err => {
    errorCallback?.(err)
  }
  if (method === 'POST') {
    request.setRequestHeader(
      'Content-Type',
      'application/x-www-form-urlencoded; charset=UTF-8'
    )
    request.setRequestHeader('x-csrf-token', getCSRFToken())
  }
  request.send(content)
}
