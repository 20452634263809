/**
 * construct URLs with query string parameters
 */
export const serialize = (params: { [key: string]: any }): string => {
  return Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&')
}

/**
 * A simple `GET` request with no Request body. Data will be chained as a query
 * string to the url.
 * @example
 * "example.com?name=John%20Doe&email=john%40example.com"
 *
 * @param {string} url - URL endpoint for API
 * @param {object} data - The data object that will be turned into a query string parameter
 */

export const ajaxGetRequest = (
  url: string,
  data: { [key: string]: any }
): void => {
  const serializeString = serialize(data)

  const getRequest = new XMLHttpRequest()
  getRequest.open('GET', ` ${url}?${serializeString}`, true)
  getRequest.setRequestHeader(
    'Content-type',
    'application/x-www-form-urlencoded; charset=UTF-8'
  )
  return getRequest.send()
}

/**
 * Function provides similar functionality to Object.entries() for legacy browsers.
 * This function has support for IE<9. After that you need an Object.keys polyfill.
 *
 * @param {object} obj - The object whose own enumerable string-keyed property [key, value] pairs are to be returned.
 * @returns An array of the given object's own enumerable string-keyed property [key, value] pairs.
 *
 * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries#polyfill|MDN Documentation}
 *
 */

export const objectEntriesPolyfill = (obj: { [x: string]: any }): any[] => {
  const ownProps = Object.keys(obj)
  let i = ownProps.length
  const resArray = new Array(i) // preallocate the Array
  while (i-- > 0) resArray[i] = [ownProps[i], obj[ownProps[i]]]

  return resArray
}
