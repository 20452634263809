import { sendBeacon } from '../utility/sendBeacon'
import { LinkClickData } from '../types/interfaces'

// ISSUE: Safari Not Logging Clicks: https://iostructure.atlassian.net/browse/IOWEBDEV-1821

export const linkTracking = (): void => {
  return document.addEventListener('click', function () {
    const currentTarget: Element | null = document.activeElement

    if (currentTarget?.tagName === 'A') {
      const selector = currentTarget.className
      const parentSelector = currentTarget.parentElement?.className

      return logClick(selector, parentSelector)
    }
  })
}

export function logClick (
  selector: string,
  parentSelector: string | undefined
): void {
  const clickEndpoint = '/element_click_counts'

  const clickData: LinkClickData = {
    elementSelector: selector,
    parentSelector: parentSelector,
    locale: window.OP_METRICS.locale,
    pathname: window.OP_METRICS.id,
    referrer: window.OP_METRICS.referrer,
    screenHeight: window.OP_METRICS.screen_height,
    screenWidth: window.OP_METRICS.screen_width,
    version: window.OP_METRICS.site_version,
    template: window.OP_METRICS.template
  }

  sendBeacon(clickEndpoint, clickData)
}
