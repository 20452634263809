import request from '../utility/request'

export const readAnalytics = (): void => {
  const pageViewAttributes: { [key: string]: any } = {
    site: window.OP_METRICS.site,
    locale: window.OP_METRICS.locale,
    type: window.OP_METRICS.type,
    id: window.OP_METRICS.id,
    referrer: document.referrer
  }

  const attributes: string[] = []
  for (const name in pageViewAttributes) {
    if (Object.prototype.hasOwnProperty.call(pageViewAttributes, name)) {
      if (
        pageViewAttributes[name] !== '' ||
        pageViewAttributes[name] === undefined
      ) {
        attributes.push(
          encodeURIComponent(name) +
            '=' +
            encodeURIComponent(pageViewAttributes[name])
        )
      }
    }
  }

  const queryString = attributes.join('&')

  try {
    request('GET', `/counts?${queryString}`, undefined)
  } catch (e) {
    // Browser is IE6 or 7
    const pixel = new Image()
    pixel.src = `https://${window.location.host}/counts?${queryString}`
  }
}
