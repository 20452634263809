import { performanceTimeline } from './timeline'
import { networkConnection } from './network'
import { paintMetrics } from './paintMetrics'
import { pageWeight } from './pageWeight'
import getCSRFToken from '../utility/csrfToken'
import { AnalyticMetadata, OptionalPerformanceData } from '../types/interfaces'

export const performanceAnalyticsData = (): void => {
  const perfData = new FormData()
  const currentPageWeight = pageWeight()
  const paintEntries = paintMetrics()
  const timeline = performanceTimeline()
  const networkData = networkConnection()

  const metadata: AnalyticMetadata = {
    locale: window.OP_METRICS.locale,
    referrer: window.OP_METRICS.referrer,
    pathname: window.OP_METRICS.id,
    template: window.OP_METRICS.template,
    screenWidth: window.OP_METRICS.screen_width,
    screenHeight: window.OP_METRICS.screen_height,
    version: window.OP_METRICS.site_version
  }

  const optional: OptionalPerformanceData = {}

  if (networkData !== undefined) {
    optional.rtt = networkData.rtt
    optional.saveData = networkData.saveData
    optional.effectiveType = networkData.effectiveType
  }

  if (paintEntries != null) {
    if (paintEntries['first-contentful-paint'] != null) {
      optional.firstContentfulPaint = paintEntries['first-contentful-paint']
    }
    if (paintEntries['first-paint'] != null) {
      optional.firstPaint = paintEntries['first-paint']
    }
  }

  optional.transferSize = currentPageWeight?.transferSize
  optional.encodedBodySize = currentPageWeight?.encodedBodySize
  optional.decodedBodySize = currentPageWeight?.decodedBodySize

  perfData.append('metadata', JSON.stringify(metadata))
  perfData.append('timeline', JSON.stringify(timeline))
  perfData.append('optional', JSON.stringify(optional))
  return sendPerfData(perfData)
}

export function sendPerfData (content: FormData): void {
  const request = new XMLHttpRequest()
  request.open('POST', '/performance_counts', true)
  request.setRequestHeader('x-csrf-token', getCSRFToken())
  if (content != null) {
    return request.send(content)
  }
}
