/**
 * `gTag` function initializes Google Tag Manager.
 *
 * @remarks
 * It requires a container ID in the metric config window object.
 *
 * @returns
 * the Google Tag Manager JS script on the page.
 *
 */

export const gTag = (): void => {
  const containerID: string | undefined = window.metricsConfig.gTag_container_ID

  if (containerID != null) {
    ;(function (dataLayerLocation: string): void {
      if (window[dataLayerLocation] == null) {
        window[dataLayerLocation] = [] as any[]
      }
      window[dataLayerLocation].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      })
      const firstScript = document.getElementsByTagName(
        'script'
      )[0] as HTMLScriptElement | null

      const gtmScript = document.createElement('script')
      const dataLayerName =
        dataLayerLocation !== 'dataLayer' ? '&l=' + dataLayerLocation : ''

      gtmScript.async = false
      gtmScript.src =
        'https://www.googletagmanager.com/gtm.js?id=' +
        containerID +
        dataLayerName

      if (firstScript != null) {
        if (firstScript.parentNode != null) {
          firstScript.parentNode.insertBefore(gtmScript, firstScript)
        } else {
          firstScript.insertBefore(gtmScript, firstScript)
        }
      }
    })('dataLayer')
  } else {
    console.error('The gTag container ID did not load properly')
  }
}
