import { isMobile } from '../utility/contextData'
import { sendBeacon } from '../utility/sendBeacon'
import { PageTimingData } from '../types/interfaces'

export const logVisit = (eventType: string | undefined): void => {
  const url = '/timing_counts'
  const eventTriggered = eventType
  const pageTimingData: PageTimingData = {
    locale: window.OP_METRICS.locale,
    referrer: window.OP_METRICS.referrer,
    pathname: window.OP_METRICS.id,
    template: window.OP_METRICS.template,
    screenWidth: window.OP_METRICS.screen_width,
    screenHeight: window.OP_METRICS.screen_height,
    version: window.OP_METRICS.site_version,
    eventType: eventTriggered,
    end: String(performance.now())
  }

  sendBeacon(url, pageTimingData)
}

export const timeOnPage = (): void => {
  const deviceType = isMobile()

  if (deviceType === 'Mobile') {
    document.addEventListener('visibilitychange', event => {
      if (document.visibilityState === 'hidden') {
        logVisit(event?.type)
      }
    })
  }

  const pageTerminationEvents = ['beforeunload', 'unload']

  if ('onpagehide' in self) {
    pageTerminationEvents.unshift('pagehide')
  }

  pageTerminationEvents.forEach(eventType =>
    window.addEventListener(eventType, event => logVisit(event?.type), true)
  )
}
