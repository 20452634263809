import { sendBeacon } from '../utility/sendBeacon'
import { ElementImpressionData } from '../types/interfaces'

export const elementImpression = (target: HTMLElement): void => {
  const io = new IntersectionObserver(
    (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      Array.prototype.slice
        .call(entries)
        .forEach(function (entry: IntersectionObserverEntry) {
          if (entry.isIntersecting) {
            const impressionData: ElementImpressionData = {
              locale: window.OP_METRICS.locale,
              pathname: window.OP_METRICS.id,
              referrer: window.OP_METRICS.referrer,
              screenHeight: window.OP_METRICS.screen_height,
              screenWidth: window.OP_METRICS.screen_width,
              version: window.OP_METRICS.site_version,
              template: window.OP_METRICS.template,
              elementSelector: entry.target?.className,
              timeSeen: entry.time.toString()
            }
            observer.disconnect()

            sendBeacon('/element_impression_counts', impressionData)
          }
        })
    }
  )

  io.observe(target)
}
