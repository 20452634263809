import { serialize, ajaxGetRequest, objectEntriesPolyfill } from './formHelpers'
import getCSRFToken from './csrfToken'

export const sendBeacon = (
  urlEndpoint: string,
  data: { [key: string]: any }
): void => {
  const dataObject: { [k: string]: any } = data

  if (
    typeof window.navigator === 'object' &&
    typeof window.navigator.sendBeacon === 'function'
  ) {
    const formData = objectToFormData(dataObject)
    try {
      if (window.navigator.sendBeacon(urlEndpoint, formData)) {
        // eslint-disable-next-line no-useless-return
        return
      }
    } catch (error) {
      ajaxGetRequest(urlEndpoint, dataObject)
    }
  } else {
    try {
      ajaxGetRequest(urlEndpoint, dataObject)
    } catch (error) {
      pixel(urlEndpoint, dataObject)
    }
  }
}

export const pixel = (
  urlEndpoint: string,
  obj: { [key: string]: any }
): HTMLImageElement => {
  const params = serialize(obj)
  const pixel = new Image()
  pixel.src = `https://${window.location.host}/${urlEndpoint}?${params}`
  return pixel
}

export const objectToFormData = (obj: { [k: string]: any }): FormData => {
  const dataObject = obj
  const formData = new FormData()
  formData.append('authenticity_token', getCSRFToken())

  if (typeof Object.entries === 'function') {
    // method returns an array of a given object's own enumerable string-keyed property [key, value] pairs
    for (const [key, value] of Object.entries(dataObject)) {
      formData.append(key, value)
    }
  } else {
    // use polyfill to simulate Object.entries and then append key, value to form data
    const results = objectEntriesPolyfill(dataObject)
    results.forEach(entry => {
      formData.append(entry[0], entry[1])
    })
  }

  return formData
}
