/**
 * The `currentLocale` function is a utility function to return the current lang attribute on the HTML tag.
 *
 * @remarks
 * Each of the CNMI websites should have a `lang` attribute on the HTML tag (`<html lang='ar'>`)
 *
 * @returns the lang attribute of the HTML tag as a `string` or `undefined` if it does not exist.
 *
 */

export const currentLocale = (): string | undefined => {
  const lang = document.querySelector('html')?.getAttribute('lang')
  if (lang != null) {
    return lang
  } else {
    return undefined
  }
}

/**
 * The `currentPage` function is a utility function to query the Location API for the device width.
 *
 * @remarks
 * The `pathname` property of the Location interface is a `USVString` containing an initial '/' followed by the path of the URL
 * (or the empty string if there is no path).
 *
 * @returns The current path of the current URL, an empty string if there is no pathname, or `undefined`.
 *
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/Location/pathname| MDN Documentation}
 */

export const currentPage = (): string | undefined =>
  'pathname' in window.location ? window.location.pathname : undefined

/**
 * `currentTemplate` function takes a element selector and return 'control'
 * or 'challenger' based on whether or not the element is currently in the DOM.
 * This is used for identifying element during an A/B experiment.
 *
 * @remarks
 * This is used for the `template` attribute for the core attributes that are collected with each analytic type.
 *
 * @param (selectorIdentifier: string)
 *
 * @returns
 * `challenger` if there is an element is the DOM,
 * `control` if one does not exist in the DOM.
 *
 */

export function currentTemplate (
  selectorIdentifier?: string | undefined
): string {
  if (selectorIdentifier != null) {
    const check = document.querySelector(selectorIdentifier)
    if (check != null) {
      return 'challenger'
    } else {
      return 'control'
    }
  } else {
    return 'control'
  }
}

/**
 * The `currentVersion` function is a utility function to return the current version of the website.
 *
 * @remarks
 * Each of the CNMI websites should have meta tag in the head of the Document, that resembles this
 * markup: `<meta name="version" content="cnmi_di_12.1.0">`
 *
 * @returns the content portion of the meta tag as a `string` or `undefined` if it does not exist.
 *
 */

export const currentVersion = (): string | undefined => {
  const versionNode = document
    .querySelector('meta[name="version"]')
    ?.getAttribute('content')
  if (versionNode != null) {
    return versionNode
  } else {
    return undefined
  }
}

/**
 * The `doNotTrack` function is a utility function to query the Navigator API for the Do Not Track setting.
 *
 * @remarks
 * The Navigator.doNotTrack property returns the user's Do Not Track setting,
 * which indicates whether the user is requesting web sites and advertisers to not track them.
 *
 * The value of the property reflects that of the doNotTrack HTTP header,
 * i.e. values of "1", "0", or "unspecified".
 *
 * MDN Documentation: {@link https://developer.mozilla.org/en-us/docs/Web/API/Navigator/doNotTrack}
 *
 * @returns `true` if `doNotTrack` is enabled on reader's device. `false` if Do Not Track
 * is either disabled or the setting is not defined.
 *
 */

export const doNotTrack = (): boolean =>
  !!('doNotTrack' in window.navigator && window.navigator.doNotTrack === '1')

/**
 * The `isFacebook` function is a utility function to test the User Agent for a Facebook identifier.
 *
 * @remarks
 * The User-Agent request header is a characteristic string that lets servers and network peers identify the application,
 * operating system, vendor, and/or version of the requesting user agent.
 *
 * MDN Documentation: {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/User-Agent}
 *
 * @returns The `true` if it is a Facebook browser, `false if it is not.
 *
 */

export const isFacebook = (): boolean => {
  const ua = navigator.userAgent.toLowerCase()
  return /fban|fbav|facebookexternalhit|fbios|fbid|fbsn/i.test(ua)
}

/**
 * The `isMobile` function is a utility function to test the User Agent for a Mobile device identifier.
 *
 * @remarks
 * The User-Agent request header is a characteristic string that lets servers and network peers identify the application,
 * operating system, vendor, and/or version of the requesting user agent.
 * @returns The `mobile` if it is a Facebook browser, `desktop if it is not.
 *
 * {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/User-Agent| MDN Documentation}
 *
 */

export const isMobile = (): string => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? 'Mobile'
    : 'Desktop'
}

/**
 * The `screenHeight` function is a utility function to query the Navigator API for the device width.
 *
 * @remarks
 * `screen.height` is a read-only property that returns the width of the screen in pixels.
 *
 * @returns The height of the screen in pixels or `undefined`.
 *
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/Screen/height| MDN Documentation}
 */

export const screenHeight = (): number | undefined =>
  'height' in window.screen ? window.screen.height : undefined

/**
 * The `screenWidth` function is a utility function to query the Navigator API for the device width.
 *
 * @remarks
 * `screen.width` is a read-only property that returns the width of the screen in pixels.
 * @returns The width of the screen in pixels or `undefined`.
 *
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/Screen/width| MDN Documentation}
 */

export const screenWidth = (): number | undefined =>
  'width' in window.screen ? window.screen.width : undefined
